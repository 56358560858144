<template>
  <section class="lg:px-14 lg:py-6 p-4 bg-white rounded-xl h-full">
    <div class="mb-10">
      <p data-test="logintext" class="text-brand-black text-[40px] font-albertExtraBold">Log In</p>
      <p class="text-brand-black/70 text-lg font-normal">
        New user? <router-link to="/register" data-test="signuptext" class="text-brand-primary font-albertBold text-lg">Sign Up</router-link>
      </p>
    </div>
    <social-auth data-test="socialauth" action_type="login" @auth-success="initAuth" />

    <form class="" @submit.prevent="submit">
      <div class="mb-4">
        <Input
          data-test="email"
          label="Email Address"
          id="email"
          type="email"
          labelFor="email"
          placeholder="e.g alisoneyo@email.com"
          v-model="auth_user.email"
        />

        <span data-test="email-error" class="text-[#D80027] uppercase">{{ emailErrorMessage }}</span>
      </div>
      <div class="relative mb-4">
        <Input
          data-test="password"
          label="Password"
          id="password"
          :type="showPassword ? 'text' : 'password'"
          labelFor="password"
          placeholder="********"
          v-model="auth_user.password"
        />

        <div class="flex absolute inset-y-0 right-0 items-center pr-3 cursor-pointer mt-12">
          <EyeClose data-test="show-password-on" v-if="!showPassword" @click="showPassword = true" fill="black" />
          <Email data-test="show-password-off" v-else @click="showPassword = false" />
        </div>
        <span data-test="password-error" class="text-[#D80027] uppercase">{{ passwordErrorMessage }}</span>
      </div>

      <div data-test="error-list" class="pt-4 mb-7 block" v-if="error != ''">
        <div class="p-4 items-center flex flex-row border-l-4 border-[#D80027] bg-[#D80027] bg-opacity-10 rounded">
          <span class="grow text-sm font-normal text-brand-black">{{ error }}</span>
          <Times class="flex-grow-0 cursor-pointer w-5" fill="black" @click.stop="error = ''" />
        </div>
      </div>

      <div class="flex items-center justify-between mb-10">
        <router-link data-test="reset-passwword-link" class="text-brand-primary cursor-pointer" to="/reset-password">Forgot Password?</router-link>
      </div>
      <Button data-test="login-button" label="Sign In" color="primary" type="submit" full :disabled="checkDisability" :loading="loading" />
    </form>
  </section>
</template>

<script setup>
import SocialAuth from '@/components/SocialAuth.vue';
import Button from '@/components/Button.vue';
import Input from '@/components/form/Input.vue';
import Email from '@/assets/icons/email.svg?inline';
import EyeClose from '@/assets/icons/eye-slash.svg?inline';
import Times from '@/assets/icons/times.svg?inline';
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { errorMessage, alert } from '@/utils/helper';

const showPassword = ref(false);
const error = ref('');
const router = useRouter();
const disabled = ref(true);
const loading = ref(false);
const store = useStore();
const emailErrorMessage = ref('');
const passwordErrorMessage = ref('');
const submittingForm = ref(false);

const auth_user = ref({
  email: '',
  password: '',
  type: 'candidate',
  auth_type: 'email',
  auth_type_id: '',
});

const checkDisability = computed(() => {
  if (auth_user.value.email === '' || auth_user.value.password === '') {
    disabled.value = true;
  } else if (submittingForm.value === true) {
    disabled.value = true;
  } else {
    disabled.value = false;
  }
  return disabled.value;
});

const initAuth = (user) => {
  auth_user.value = user;
  submit();
};

async function submit() {
  try {
    submittingForm.value = true;
    error.value = '';
    loading.value = true;
    const { user, message } = await store.dispatch('auth/login', auth_user.value);

    //request otp and redirect to otp verify page for unverified users
    if (!user.emailIsVerified && auth_user.value.auth_type == 'email') {
      await store.commit('global/setForm', { key: 'email', value: user.email });
      await store.dispatch('auth/requestOtp', { email: user.email });
      return router.push('/register-verify');
    }

    alert(message, 'success');

    const path = store.getters['global/getRedirectUrl'];
    return router.push(path || '/jobs');
  } catch (err) {
    process.env.NODE_ENV === 'development' ? console.warn(err) : '';
    loading.value = false;
    submittingForm.value = false;
    if (!err) {
      alert('Request was not sent, not enough data to tell why.', 'error');
      return;
    }
    alert(errorMessage(err), 'error');

    if (err?.response?.status === 422) {
      const { errors } = err.response.data;
      const { email, password } = errors;
      if (email) {
        emailErrorMessage.value = email[0];
      }
      if (password) {
        passwordErrorMessage.value = password[0];
      }
    }
  } finally {
    auth_user.value.auth_type = 'email';
  }
}
</script>
